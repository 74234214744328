/* eslint-disable class-methods-use-this */
import ApiService from '../common/api.service'

import decrypt from '../common/decrypt'

class OperatorService {
  getAllOperater() {
    return ApiService.get('/all/operator').then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }
}

export default new OperatorService()
